import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IAccounts, defaultValue } from 'app/shared/model/accounts.model';
import { Admin } from 'app/shared/model/admin.model';

const initialState: EntityState<IAccounts> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  countryList: [],
  signatureData: null,
  // approvalData:any,
  // approvalData: null,

};
export interface StatusData {
  status: string;
  accountId: string;
  description: string;
}
export interface rejectedData {

  account_number: string;
  description: string;
}
const apiUrl = `${SERVER_API_URL}api/accounts`;
const admin_url = `${SERVER_API_URL}api/admin/accounts/status`;
const admin_details = `${SERVER_API_URL}api/admin/accounts`;
const need_info = `${SERVER_API_URL}api/admin/account-status-changes`;
const reject_mail = `${SERVER_API_URL}api/admin/accounts/ibkr-rejected-mail`;
const info_mail = `${SERVER_API_URL}api/admin/send-mail`;
const pendingtaskUrl = `${SERVER_API_URL}api/admin/update/pending-task`;
const ibkraccountledgerstatus = `${SERVER_API_URL}api/admin/ibkr-account-ledger/update-status`;



const customerDetails=`${SERVER_API_URL}api/admin/details`;
export const getEntities = createAsyncThunk('accounts/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}`;
  return axios.get<IAccounts[]>(requestUrl);
});

export const getPendingTaskEntity = createAsyncThunk(
  'accounts/pending-task', async () => {
      const result =await axios.get<any>(`${pendingtaskUrl}`);
      return  result;
  }
);

export const getNeedInfo = createAsyncThunk("admin/need-info", async ({ accountId }: { accountId: string }) => {
  const requestUrl = `${need_info}/${accountId}`;
  const response = await axios.get(requestUrl);
  return response.data;
}
);


export const getAdminAccounts = createAsyncThunk("admin/account/list", async ({ accountId }: { accountId: string }) => {
  const requestUrl = `${admin_details}/${accountId}`;
  return axios.get<IAccounts[]>(requestUrl)
})
export const postAdminApproval = createAsyncThunk(
  'admin/approve',
  async ({ accountId, status, description }: StatusData) => {
    try {
      const response = await axios.post(`${admin_url}/${accountId}`,  { status, description },{ timeout: 18000 });
      return response.data;
    } catch (error) {

      console.error('Error while calling postAdminApproval API:', error);
      throw error;
    }
  }
);



export const sendRejectedMail = createAsyncThunk(
  'admin/sendmail',
  async ({ account_number, description }: rejectedData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        reject_mail,
        { account_number, description }
        
      );
      return response.data;
    } catch (error) {
      console.error('Error while calling send mail API:', error);
      return rejectWithValue(error.response.data);
    }
  }
);
export const sendInfoMail = createAsyncThunk(
  'admin/sendinfomail',
  async ({ data }:any) => {
    try {
      const response = await axios.post(info_mail, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error while calling send mail API:', error);
    }
  }
);
export const getAdminPendingEntities = createAsyncThunk(
  'accounts/fetch_admin_list_pending',
  async ({
    startDate,
    endDate,
    page,
    size,
    status,
  }: {
    startDate: string;
    endDate: string;
    page: number;
    size: number;
    status: string;
  }) => {
    const requestUrl = `${SERVER_API_URL}api/admin/accounts?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&status=${status}`;
    return axios.get<Admin[]>(requestUrl);
  },
);

export const downloadDocument = createAsyncThunk(
  'accounts/download_document',
  async ({ fileName, accountNumber }: { fileName: string; accountNumber: string }) => {
    const requestUrl = `${SERVER_API_URL}api/admin/document/download/${fileName}?accountNumber=${accountNumber}`;
    return axios.get(requestUrl, { responseType: 'blob' });
  },
);
export const getAdminCompleteEntities = createAsyncThunk(
  'accounts/fetch_admin_list_active',
  async ({
    startDate,
    endDate,
    page,
    size,
    status,
  }: {
    startDate: string;
    endDate: string;
    page: number;
    size: number;
    status: string;
  }) => {
    const requestUrl = `${SERVER_API_URL}api/admin/accounts?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&status=${status}`;
    return axios.get<Admin[]>(requestUrl);
  },
);

export const getAdminRejectEntities = createAsyncThunk(
  'accounts/fetch_admin_list_reject',
  async ({
    startDate,
    endDate,
    page,
    size,
    status,
  }: {
    startDate: string;
    endDate: string;
    page: number;
    size: number;
    status: string;
  }) => {
    const requestUrl = `${SERVER_API_URL}api/admin/accounts?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}&status=${status}`;
    return axios.get<Admin[]>(requestUrl);
  },
);

const formatToGMT = (date) => {
  const d = new Date(date);
  return d.toISOString().split('.')[0] + 'Z'; // Keeps "YYYY-MM-DDTHH:MM:SSZ" format
};

export const getIbkrAccountLedger = createAsyncThunk(
  'accounts/fetch_ibkr_account_ledger',
  async ({
    startDate,
    endDate,
    status,
    transactionType,
  }: {
    startDate: string;
    endDate: string;
    status: string;
    transactionType: string;
  }) => {
    const formattedStartDate = formatToGMT(startDate);
    const formattedEndDate = formatToGMT(endDate);

    const requestUrl = `${SERVER_API_URL}api/admin/get-ibkr-account-ledgers?startDate=${formattedStartDate}&endDate=${formattedEndDate}&status=${status}&transactionType=${transactionType}`;
    return axios.get<Admin[]>(requestUrl);
  },
);

export const ibkraccountstatus = createAsyncThunk(
  'accounts/ibkr-account-status',
  async ({ id, status }: { id: string; status: string }, thunkAPI) => {
    const result = await axios.put<IAccounts>(`${ibkraccountledgerstatus}/${id}`);
    console.log("iddddd",id);
    
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'accounts/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IAccounts>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getCustomerDetailsList = createAsyncThunk(
  'accounts/customer_details_fetch_entity',
  async (customerId: any) => {
    const requestUrl = `${customerDetails}/${customerId}`;
   const response=axios.get<any>(requestUrl);
   return response;
  },
  { serializeError: serializeAxiosError },
  );

export const createEntity = createAsyncThunk(
  'accounts/create_entity',
  async (entity: IAccounts, thunkAPI) => {
    try {
      const result = await axios.post<IAccounts>(apiUrl, cleanEntity(entity));
      return result;
    } catch (error) {
      return error;
    }
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'accounts/update_entity',
  async (entity: any, thunkAPI) => {
    const result = await axios.put<IAccounts>(`${apiUrl}/${entity?.accountId}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'accounts/partial_update_entity',
  async (entity: IAccounts, thunkAPI) => {
    const result = await axios.patch<IAccounts>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'accounts/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IAccounts>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const AdminSlice = createEntitySlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountLastLevel(state, action) {
      state.entity.last_level = action.payload;
    },
    setCountriesList(state, action) {
      state.countriesList = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...state.entity, ...action.payload.data };
      })
      .addCase(getAdminAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = { ...state.entity, ...action.payload.data };
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = { ...defaultValue, last_level: 1 };
      })
      .addCase(postAdminApproval.pending, (state) => {
        state.loading = true;

      })
      .addCase(postAdminApproval.fulfilled, (state, action) => {
      })
      .addCase(postAdminApproval.rejected, (state, action) => {
        state.loading = false;

      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getAdminPendingEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isPending(downloadDocument), state => {
        state.loading = true;
      })
      .addMatcher(isFulfilled(downloadDocument), (state, action) => {
        state.loading = false;
        // Handle the blob data here, for example, creating a link to download the file
        // const url = window.URL.createObjectURL(new Blob([action.payload]));
        const link = document.createElement('a');
        // link.href = url;
        link.setAttribute('download', action.meta.arg.fileName); // Use the file name from the thunk's arguments
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  },
});

export default AdminSlice.reducer;
