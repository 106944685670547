import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch, useAppSelector } from 'app/config/store'
import { depositConfig, getEntity, getIbkr, getIbkrPending, getPortfolio, setAccountLastLevel } from 'app/entities/accounts/accounts.reducer'
import { getCustomerDetails } from 'app/entities/customer/customer.reducer';
import { getPendingTask, ibkrportal, withdrawibkrportal } from 'app/modules/profile/profile.reducer'
import React, { useEffect, useState } from 'react'
import {  Accordion, Card, CardBody } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { Alert, Button, Col, Row } from 'reactstrap'
 

const MyStarLight = () => {
  const [ibkrData, setIbkrData] = useState(null);
  const [ibkrPending, setIbkrPending] = useState([]);
  const accountId = useAppSelector(state => state.accounts?.entity?.accountId)

  const [portfolioData, setPortfolioData] = useState(null);
  const [ibkrNum, setIbkrNum] = useState(null);
  const [data, setData] = useState([]);
  const [masterType, setMasterType] = useState();
  const [accountNumber, setAccountNumber] = useState(null)
  const accountsEntity = useAppSelector(state => state.accounts.entity);
  const mainAccountsDetails = useAppSelector(state => state.accounts?.entity);
  const [completeDocumentation, setCompleteDocumentation] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [holderType, setHolderType] = useState();
  const [PendingTasks, setPendingTasks] = useState([]);
  const [signaturePendingData, setSignaturePendingData] = useState([]);

  const [secondaryCustomerId, setSecondaryCustomerId] = useState(null)

  const [depositDetails, setDepositDetails] = useState(null);
  const dispatch = useAppDispatch();
  const { last_level } = useAppSelector(state => state.accounts.entity);
  const navigate = useNavigate();

  
  // useEffect(() => {
  //   dispatch(getIbkr()).then((response: any) => {
  //     if (response?.payload) {
  //       setIbkrData(response.payload);
  //       setIbkrNum(response.payload.ibkr_account_number)
  //     }
  //   });
  // }, [dispatch]);

    useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  
  useEffect(() => {
    const fetchIbkrData = async () => {
      setIsLoading(true); 
      try {
        const response = await dispatch(getIbkr());
        if (response?.payload) {
          setIbkrData(response.payload);
          setIbkrNum(response.payload.ibkr_account_number)
        }
      } catch (error) {
        console.error('Failed to fetch IBKR data:', error);
      } finally {
        setIsLoading(false); 
      }
    };
    fetchIbkrData();
  }, [dispatch]);

  const [ibkrdefaultActiveKeys, setIbkrDefaultActiveKeys] = useState([]);


  useEffect(() => {
    dispatch(getIbkrPending()).then((response: any) => {
      if (response?.payload) {
        setIbkrPending(response?.payload);
        // setIbkrNum(response.payload.ibkr_account_number)
        const activeKeys = response?.payload?.map((_, index) => index?.toString());
        setIbkrDefaultActiveKeys(activeKeys); // Set all tasks to be open by default
      }
     
    });
  }, [dispatch]);

  useEffect(() => {
    if(ibkrData){
    dispatch(getPortfolio(ibkrNum)).then((response: any) => {
      if (response?.payload) {
        setPortfolioData(response.payload);
      }

    });
    
  }}, [ibkrData]);

  useEffect(() => {
    dispatch(getAllPendingTask)
    dispatch(depositConfig()).then((response:any) => {
      setDepositDetails(response?.payload);
    });
  }, []);
  
  const onClickPendingTask = (level: number = 1) => {
    dispatch(setAccountLastLevel(level));
    navigate('/accounts');
  };
    const onClickCompanyPendingTask = (level: number = 1) => {
      dispatch(setAccountLastLevel(level));
      navigate('/company');
    };
  const getAllPendingTask=() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {

        setData(response?.payload?.data?.signature_pending)
        const pendingAccountsList = response?.payload?.data?.pending_accounts;
        const signaturePendingList = response?.payload?.data?.signature_pending
        const secondaryId = signaturePendingList?.[0]?.secondary_customer_id;
        setSecondaryCustomerId(secondaryId)
        setMasterType(signaturePendingList?.[0]?.accountMasterType)

        if (signaturePendingList) {
          setAccountNumber(signaturePendingList?.[0]?.accountId)
        }

        if (pendingAccountsList && pendingAccountsList.length > 0 || signaturePendingList && signaturePendingList.length > 0) {
          let firstAccount: any = pendingAccountsList.filter(
            (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          );
          // const signaturePendingAccount: any = response.payload.data.signature_pending.filter(
          //   (accountobj: { accountId: string }) => accountobj.accountId === sessionStorage.getItem('accountid'),
          // );
          const signaturePendingAccount: any = response.payload.data.signature_pending
          let level = '';
          let firstAccountId = '';
          if (pendingAccountsList.length > 0) {
            level = pendingAccountsList[0]?.last_level;
            firstAccountId = pendingAccountsList[0]?.accountId;
          } else {
            if (signaturePendingAccount.length > 0) {
              level = signaturePendingAccount[0]?.last_level;
              firstAccountId = signaturePendingAccount[0]?.accountId;
              firstAccount = signaturePendingAccount;
            }
          }
          dispatch(setAccountLastLevel(level));          
          setPendingTasks(pendingAccountsList);
          setSignaturePendingData(signaturePendingAccount);


          const customerId = accountsEntity.customerId;
          const filteredHolderData = pendingAccountsList
            .filter((type: any) => {
              return type.customerId === customerId;
            })
            .map((type: any) => type.accountHolderType);

          setHolderType(filteredHolderData);

          if (firstAccountId !== '') {
            dispatch(getEntity(firstAccountId));
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  const isTaskPending = (taskId: number) => {
    const pendingTasks = [2, 3, 4, 5, 6, 7, 8, 9,10, 99, 100, 101, 102, 103, 104];
    return pendingTasks.includes(taskId);
  };

  const handleIBKRPortalLinkClick = async () => {
    try {
      const resultAction = await dispatch(ibkrportal());

      if (resultAction && resultAction.payload) {
        const portalUrl = resultAction.payload;
        window.open(portalUrl, '_blank');
      } else {
        console.error('IBKR Portal URL not found');
      }
    } catch (error) {
      console.error('Error fetching IBKR Portal URL:', error);
    }
  };

   const handlePortalLinkClick = async () => {
      try {
        const resultAction = await dispatch(withdrawibkrportal());
  
        if (resultAction && resultAction.payload) {
          const portalUrl = resultAction.payload;
          window.open(portalUrl, '_blank');
        } else {
          console.error('IBKR Portal URL not found');
        }
      } catch (error) {
        console.error('Error fetching IBKR Portal URL:', error);
      }
    };

 
  const copyToClipboard = (text) => {
    if (!text) return; // Don't attempt to copy if there's no text
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };
 
  return (
    
  <div className="col-12 pb-5">
      
{!secondaryCustomerId && mainAccountsDetails.accountHolderType?.toLowerCase() !== 'organization' && (
  <>
    { last_level > 1 && signaturePendingData ? (
      <div className="pt-5 accordion-div-dashboard">
        <Card className="dashboard-custom-card pt-3">
          <CardBody>
            <div className="dashboard-card-heading">My Pending Items & Tasks</div>
            <Accordion alwaysOpen defaultActiveKey={['0','1','2','3','4','5','6','7','8','9','10','11']}>
              {last_level < 3 && isTaskPending(2) && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                        &nbsp;&nbsp;Primary Applicant Details
                          </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div>
                        <Button  onClick={() => onClickPendingTask(2)} className="button-dashboard-custom-submit">
                          Primary Applicant Details
                        </Button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            {last_level < 4 && isTaskPending(3) && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>                                              
                        &nbsp;&nbsp;Primary Applicant Employment Details
                          </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div>
                        {last_level === 3 ? (
                       <Button onClick={() => onClickPendingTask(3)} className="button-dashboard-custom-submit">
                       Primary Applicant Employment Details
                     </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                          Primary Applicant Employment Details
                        </span>
                      )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {mainAccountsDetails?.accountHolderType !== 'Individual' && last_level < 5 && isTaskPending(4) && (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                          <div className="dashboard-card-sub-heading"> 
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Secondary Applicant Details
                          </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div>
                         {last_level === 4 ? (
                          <Button onClick={() => onClickPendingTask(4)} className="button-dashboard-custom-submit">
                          Secondary Applicant Details 
                           </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                           Secondary Applicant Details 
                        </span>
                      )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {last_level < 6 && isTaskPending(5) && (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Anti-Money Laundering Requirements
                        </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                      <div>
                      {(last_level === 5 || (last_level === 4 && mainAccountsDetails?.accountHolderType === 'Individual')) ? (
                      <Button onClick={() => onClickPendingTask(5)} className="button-dashboard-custom-submit">
                          Anti-Money Laundering Requirements
                        </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                          Anti-Money Laundering Requirements
                        </span>
                      )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {last_level< 7 && isTaskPending(6)&&(
                <Accordion.Item eventKey="3">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Your Net Worth and Annual Income
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>    
                      {last_level === 6 ? (
                        <Button onClick={() => onClickPendingTask(6)} className="button-dashboard-custom-submit">
                      Your Net Worth and Annual Income
                      </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                           Your Net Worth and Annual Income
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              )}
              {last_level< 8 && isTaskPending(7)&&(
                <Accordion.Item eventKey="4">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Politically Exposed Person (PEP)
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                  
                      {last_level === 7 ? (
                          <Button onClick={() => onClickPendingTask(7)} className="button-dashboard-custom-submit">
                      Politically Exposed Person (PEP)
                      </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                           Politically Exposed Person (PEP)
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 9 && isTaskPending(8)&&(
                <Accordion.Item eventKey="5">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                            &nbsp;&nbsp;Regulated Employee Clients
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                 
                      {last_level === 8 ? (
                      <Button onClick={() => onClickPendingTask(8)} className="button-dashboard-custom-submit">
                      Regulated Employee Clients 
                     </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                           Regulated Employee Clients 
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
                {last_level< 10 && isTaskPending(9)&&(
                <Accordion.Item eventKey="6">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                            &nbsp;&nbsp;Know Your Customer Verification
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                 
                      {last_level === 9 ? (
                      <Button onClick={() => onClickPendingTask(9)} className="button-dashboard-custom-submit">
                      Know Your Customer Verification 
                     </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                           Know Your Customer Verification
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 11 && isTaskPending(10)&&(
                <Accordion.Item eventKey="7">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;My Starlight Smart Reserve Application Summary
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                  
                      {last_level === 10 ? (
                        <Button onClick={() => onClickPendingTask(10)} className="button-dashboard-custom-submit">
                        My Starlight Smart Reserve Application Summary
                        </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                        My Starlight Smart Reserve Application Summary
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 101 && isTaskPending(100)&&(
                <Accordion.Item eventKey="8">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Statement of Suitability
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>
                      {last_level === 100? (
                     <Button onClick={() => onClickPendingTask(100)} className="button-dashboard-custom-submit">
                     Statement of Suitability
                     </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                        Statement of Suitability
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
               {last_level< 102 && isTaskPending(101)&&(
                <Accordion.Item eventKey="9">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Starlight Smart Reserve Model Portfolio Description
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                    
                      {last_level === 101? (
                     <Button onClick={() => onClickPendingTask(101)} className="button-dashboard-custom-submit">
                     Starlight Smart Reserve Model Portfolio Description
                     </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                        Starlight Smart Reserve Model Portfolio Description
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
               {last_level< 103 && isTaskPending(102)&&(
                <Accordion.Item eventKey="10">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;W-8BEN Primary Form
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                  
                      {last_level === 102? (
                      <Button onClick={() => onClickPendingTask(102)} className="button-dashboard-custom-submit">
                      W-8BEN
                      </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                         W-8BEN
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
              {last_level< 104 && isTaskPending(103)&&(
                <Accordion.Item eventKey="11">
                <Accordion.Header>
                          <div className="dashboard-card-sub-heading">
                          <Button className="button-pending-dashboard">
                            Action Required
                          </Button>
                        &nbsp;&nbsp;Interactive Brokers Ireland Standard Terms And Disclosures
                          </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                    <div>                  
                      {last_level === 103? (
                       <Button onClick={() => onClickPendingTask(104)} className="button-dashboard-custom-submit">
                       Interactive Brokers Ireland Standard Terms And Disclosures
                       </Button>
                      ) : (
                        <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                         Interactive Brokers Ireland Standard Terms And Disclosures
                        </span>
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              )}
            </Accordion>
          </CardBody>
        </Card>
      </div>
    ) : (
      <>
      {isLoading ? (
        <div className="loader-container dashboard-loader-container">
          <div className="spinner-border text-primary custom-spinner" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
      <>
      
      {ibkrNum?(
        <>
      <div className='heading-dashboard'>  My Starlight: {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.my_starlight || 0)}
      </div>
      <div className="pt-3">
          <Card className=' dashboard-custom-card'>
              <CardBody>
            <div className='dashboard-card-heading'>
              My Starlight Smart Account:&nbsp;
              <span className='span-account-number px-2 '>{ibkrNum}</span>
              <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrNum)}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
            icon={faCopy}
            style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
            onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
            onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
            </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Investments:</div>
                      <div className="col sub-content-dashboard"> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.investment || 0)}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Cash:</div>
                      <div className="col sub-content-dashboard"> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.cash || 0)}</div>
                  </div>
                  <div className="row pt-2">
                      <div className="col-3 dashboard-card-sub-heading">My Gains  & Interest:</div>
                      <div className="col sub-content-dashboard"> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(portfolioData?.gain_and_interest || 0)}</div>
                  </div>
              </CardBody>
          </Card>
      </div>
      <div className="pt-3">
      <Card className='dashboard-custom-card'>
      <CardBody>
      <div className='dashboard-card-heading'>
      My Starlight Portfolio
      </div>
      <div className="pt-2">
      <table className='dashboard-table-custom'>
        <thead>
          <tr>
            <th>Investment</th>
            <th>Principal Invested</th>
            <th>Gains & Interest</th>
            <th>Market Value (€)</th>
          </tr>
        </thead>
        <tbody>
        {portfolioData && portfolioData.starlight_portfolio &&
  Object.entries(portfolioData.starlight_portfolio)
    // Sort to place "BASE" first
    .sort(([keyA], [keyB]) => (keyA === "BASE" ? -1 : keyB === "BASE" ? 1 : 0))
    .map(([key, value]: [string, { investment: number; gain_and_interest: number; market_value: number }]) => (
      <tr key={key}>
        {/* Rename "BASE" to "Starlight Smart Reserve" */}
        <td className='sub-content-dashboard'>
        {key === "BASE"
          ? "Starlight Smart Reserve"
          : key === "EUR"
          ? "Cash(Eur)"
          : `Cash(${key})`}
        </td>
        <td className='sub-content-dashboard'> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value?.investment || 0)}</td>
        
        {/* Only show gain_and_interest for "BASE"; add empty cell for other rows */}
        {key === "BASE" ? (
          <td className='sub-content-dashboard'> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value?.gain_and_interest || 0)}</td>
        ) : (
          <td className='sub-content-dashboard'></td> // Empty cell for alignment
        )}
        
        <td className='sub-content-dashboard'> {new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(value?.market_value || 0)}</td>
      </tr>
    ))
}
  </tbody>

      </table>
    </div>
   </CardBody>
   </Card>

   {(ibkrData?.ibkr_temp_password || ibkrData?.account_opening_status === 'PENDING') && (
   <div className="pt-3 accordion-div-dashboard">
   <Card className=' dashboard-custom-card'>
   <CardBody>
   <div className='dashboard-card-heading'>
   My Pending Items & Tasks
      </div>
      <Accordion alwaysOpen defaultActiveKey={['0','1']}>
      {ibkrData?.ibkr_temp_password && (
      <Accordion.Item eventKey="0">
                  <Accordion.Header>
                  <Button className="button-pending-dashboard">
                          Action Required
                        </Button>
                    <div className="dashboard-card-sub-heading ms-2">Reset my Interactive Brokers Investment Portal Password</div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="sub-content-dashboard ">
                      <div className="col-12">To complete the secure set-up of your Starlight Smart Account we kindly request that you reset the Temporary Password provided below in the Investment Portal</div>
                      <div className="col-12 pt-3">
                  <Button 
                   className='button-dashboard-custom-submit'
                   onClick={() => handleIBKRPortalLinkClick()}
                   >
                    Reset Password
                  </Button>
              </div>
                    </div>
                    <div className="d-flex pt-3">
                      <span className="dashboard-card-sub-heading">Username:</span>&nbsp;
                      <span className="sub-content-dashboard">{ibkrData?.ibkr_username}</span>
                      <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrData?.ibkr_username)}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
            icon={faCopy}
            style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
            onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
            onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
                  </div>
                  <div className="d-flex pt-3">
                      <span className=" dashboard-card-sub-heading">Temporary Password:</span>&nbsp;
                      <span className=" sub-content-dashboard">{ibkrData?.ibkr_temp_password}</span>
                      <button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrData?.ibkr_temp_password)}
            title="Copy to clipboard"
          >
              <FontAwesomeIcon
            icon={faCopy}
            style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
            onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
            onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
              />
              </button>
                  </div>
                  </Accordion.Body>
                </Accordion.Item>
      )}
      {ibkrData?.account_opening_status==='PENDING' &&(
        <><Accordion.Item eventKey="0">
          <Accordion.Header>
            <Button className="button-pending-dashboard">
              Action Required
            </Button>
            <div className="dashboard-card-sub-heading ms-2">Set Up Your Nominated Bank Account Details for Withdrawals</div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="sub-content-dashboard ">
              <div className="col-12">In order to setup your nominated bank account for withdrawals in the Investment Portal, please have your
                Interactive Broker's Username and Password to hand. Your Interactive Broker's Username is "<b>{ibkrData?.ibkr_username}</b>"<button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrData?.ibkr_username)}
            title="Copy to clipboard"
          ><FontAwesomeIcon
          icon={faCopy}
          style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
          onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
          onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
            />
            </button>.
                If you cannot recall your Interactive Broker's Password you may reset the password here:
                <Button
                  className='reset-dashboard-button_ippw ms-2 '
                  onClick={() => {
                    window.open('https://www.clientam.com/sso/PasswordReset?partnerID=Starlight', '_blank');
                  }}>
                  IPPW Reset
                </Button>
              </div>
              <div className="col-12 pt-3">
                <Button
                  className='button-dashboard-custom-submit'
                  onClick={() => handlePortalLinkClick()}>
                  Set Up Bank Account
                </Button>

              </div>
            </div>
          </Accordion.Body>
            </Accordion.Item>
            </>
      )}
      {ibkrData?.withdrawal_status==='PENDING' &&(
         <><Accordion.Item eventKey="0">
            <Accordion.Header>
              <Button className="button-pending-dashboard">
                Action Required
              </Button>
              <div className="dashboard-card-sub-heading ms-2">Withdraw Funds to Nominated Bank Account</div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="sub-content-dashboard ">
                <div className="col-12">In order to setup your nominated bank account for withdrawals in the Investment Portal, please have your
                  Interactive Broker's Username and Password to hand. Your Interactive Broker's Username is "<b>{ibkrData?.ibkr_username}</b>"<button
            className="btn btn-link p-0 ms-2"
            onClick={() => copyToClipboard(ibkrData?.ibkr_username)}
            title="Copy to clipboard"
          ><FontAwesomeIcon
          icon={faCopy}
          style={{  color: "#949494" ,cursor: 'pointer', transition: 'transform 0.2s' }}
          onMouseEnter={(e:any) => e.target.style.transform = 'scale(1.2)'}
          onMouseLeave={(e:any) => e.target.style.transform = 'scale(1)'}
            />
            </button>.
                  If you cannot recall your Interactive Broker's Password you may reset the password here:
                  <Button
                    className='reset-dashboard-button_ippw ms-2'
                    onClick={() => {
                      window.open('https://www.clientam.com/sso/PasswordReset?partnerID=Starlight', '_blank');
                    }}>
                    IPPW Reset
                  </Button>
                </div>
                <div className="col-12 pt-3">
                  <Button
                    className='button-dashboard-custom-submit'
                    onClick={() => handlePortalLinkClick()}>
                    Withdraw Cash
                  </Button>

                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item> 
          </>
      )}
                </Accordion>
                
   </CardBody>
    </Card>
   </div>
   )}

{/* {ibkrData?.ibkr_temp_password && (
   <div className="pt-3 accordion-div-dashboard">
   <Card className=' dashboard-custom-card'>
   <CardBody>
      <Accordion alwaysOpen defaultActiveKey="0">
      <Accordion.Item eventKey="1">
                  <Accordion.Header>
                  <Button className="button-pending-dashboard">
                   Set Up Nominated Bank Account
                  </Button>
                    <div className="dashboard-card-sub-heading ms-2">Set Up Your Nominated Bank Account Details for Withdrawals</div>
                  </Accordion.Header>
                </Accordion.Item>
                </Accordion>
   </CardBody>
    </Card>
   </div>
   )} */}

   {ibkrPending.length>0 &&(
   <div className="pt-5 accordion-div-dashboard">
      <Card className="dashboard-custom-card pt-3">
        <CardBody>
          <div className="dashboard-card-heading">My Pending Items & Tasks</div>
          <Accordion alwaysOpen defaultActiveKey={ibkrdefaultActiveKeys}>
            {ibkrPending?.map((task, index) => (
              <Accordion.Item eventKey={index?.toString()} key={task?.formNumber}>
                <Accordion.Header>
                <div className="dashboard-card-sub-heading">
                {task?.state === 'Received - Being Processed' ? (
                  <Button className="button-review-dashboard" >
                    Under Review
                  </Button>
                ) : task?.state === 'Completed' ? (
                  <Button className="button-completed-dashboard" >
                    Completed
                  </Button>
                ) : (
                  <Button className="button-pending-dashboard">
                    Action Required
                  </Button>
                )}
                 &nbsp;&nbsp;{task?.formName}
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="sub-content-dashboard">
                  To complete this task please login to your Investment Portal here:
                    <div className="col-12 pt-3">
                      <Button className="button-dashboard-custom-submit"
                      onClick={() => handleIBKRPortalLinkClick()}
                      >
                      Investment Portal
                      </Button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </CardBody>
      </Card>
    </div>
    )}
  </div>
        </>
      ):ibkrPending.length>0 ?(
        <>
        <Row className='justify-content-center'>
        <Card className='col-md-8 form-custom-card'>
        <CardBody>
          <Col md="12" className='sub-content-dashboard'>
            {/* <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
              Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!</Alert> */}
              <div className='dashboard-card-sub-heading'>Your Starlight Smart Account application is currently under review.</div>
              <div className='pt-3'>We will be in touch by email if we need anything else and will let you know once your account has been approved.</div>
              <div className='pt-3'>Thank you for your patience!</div>
              <div className='pt-3 dashboard-card-sub-heading'>The Starlight Wealth Team</div>
          </Col>
          </CardBody>
          </Card>
        </Row>
          <div className="pt-5 accordion-div-dashboard">
          <Card className="dashboard-custom-card pt-3">
        <CardBody>
          <div className="dashboard-card-heading">My Pending Items & Tasks</div>
          <Accordion alwaysOpen defaultActiveKey={ibkrdefaultActiveKeys}>
            {ibkrPending?.map((task, index) => (
              <Accordion.Item eventKey={index?.toString()} key={task?.formNumber}>
                <Accordion.Header>
                <div className="dashboard-card-sub-heading">
                {task?.state === 'Received - Being Processed' ? (
                  <Button className="button-review-dashboard">
                    Under Review
                  </Button>
                ) : task?.state === 'Completed' ? (
                  <Button className="button-completed-dashboard" >
                    Completed
                  </Button>
                ) : (
                  <Button className="button-pending-dashboard">
                    Action Required
                  </Button>
                )}
                 &nbsp;&nbsp;{task?.formName}
                </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="sub-content-dashboard">
                  To complete this task please login to your Investment Portal here:
                    <div className="col-12 pt-3">
                      <Button className="button-dashboard-custom-submit"
                      onClick={() => handleIBKRPortalLinkClick()}
                      >
                      Investment Portal
                      </Button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </CardBody>
      </Card>
      </div>
        </>
      ):(
      <Row className='justify-content-center'>
        <Card className='col-md-8 form-custom-card'>
        <CardBody>
      <Col md="12" className='sub-content-dashboard'>
        {/* <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
        Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!        </Alert> */}
              <div className='dashboard-card-sub-heading'>Your Starlight Smart Account application is currently under review.</div>
              <div className='pt-3'>We will be in touch by email if we need anything else and will let you know once your account has been approved.</div>
              <div className='pt-3'>Thank you for your patience!</div>
              <div className='pt-3 dashboard-card-sub-heading'>The Starlight Wealth Team</div>
      </Col>
      </CardBody>
      </Card>
    </Row>
    )}
     
  </>
    )}
    </>
    )}
  </>
)}

  {/* {accountId && mainAccountsDetails.accountHolderType?.toLowerCase() === 'organization' && (
                  <>
                    {last_level >= 1 ? (
                       <div className="pt-5 accordion-div-dashboard">
                         <Card className="dashboard-custom-card pt-3">
                         <CardBody>
                         <div className="dashboard-card-heading">My Pending Items & Tasks</div>
                         <Accordion alwaysOpen defaultActiveKey={['0','1','2','3','4','5','6','7','8','9','10']}>
                          {last_level < 100 ? (
                            <div className="py-2 pending-task new-profile-color">Activities:</div>) : (
                            <></>)}
                                {last_level < 101 ? (
                                  <thead className='new-form-table-row-two'>
                                    <tr>
                                      <th>Tasks</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>) : (
                                  <>
                                    <Row className='justify-content-center bg-white'>
                                      <Col md="6">
                                        <Alert className="mt-3 alertclrr " isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
                                          You have No Outstanding Tasks
                                        </Alert>
                                      </Col>
                                    </Row>
                                  </>
                                )}
 
                                  {last_level < 2 && (
                                    <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                    <div className="dashboard-card-sub-heading">
                                            <Button className="button-pending-dashboard">
                                            Action Required
                                          </Button>
                                          &nbsp;&nbsp;Company Details
                                          </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                        <div>
                                          <Button  onClick={() => onClickCompanyPendingTask(2)} className="button-dashboard-custom-submit">
                                          Company Details
                                          </Button>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                    </Accordion.Item>                    
                                  )}
                                  {last_level < 3 && (
                                      <Accordion.Item eventKey="1">
                                      <Accordion.Header>
                                              <div className="dashboard-card-sub-heading">
                                              <Button className="button-pending-dashboard">
                                                Action Required
                                              </Button>                                              
                                            &nbsp;&nbsp;Key People associated with the Company
                                              </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                          <div>
                                            {last_level === 2 ? (
                                           <Button onClick={() => onClickCompanyPendingTask(3)} className="button-dashboard-custom-submit">
                                           Key People associated with the Company
                                         </Button>
                                          ) : (
                                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                              Key People associated with the Company
                                            </span>
                                          )}
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>                            
                                  )}
                                  {last_level < 4 && (
                                     <Accordion.Item eventKey="2">
                                     <Accordion.Header>
                                             <div className="dashboard-card-sub-heading"> 
                                             <Button className="button-pending-dashboard">
                                               Action Required
                                             </Button>
                                           &nbsp;&nbsp;Details of Company Key People
                                             </div>
                                     </Accordion.Header>
                                     <Accordion.Body>
                                       <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                         <div>
                                            {last_level === 3 ? (
                                             <Button onClick={() => onClickCompanyPendingTask(4)} className="button-dashboard-custom-submit">
                                             Details of Company Key People 
                                              </Button>
                                         ) : (
                                           <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                              Details of Company Key People 
                                           </span>
                                         )}
                                         </div>
                                       </div>
                                     </Accordion.Body>
                                   </Accordion.Item>                                  
                                  )}
                                  {last_level < 5 && (
                                     <Accordion.Item eventKey="3">
                                     <Accordion.Header>
                                             <div className="dashboard-card-sub-heading"> 
                                             <Button className="button-pending-dashboard">
                                               Action Required
                                             </Button>
                                           &nbsp;&nbsp;Company Financials
                                             </div>
                                     </Accordion.Header>
                                     <Accordion.Body>
                                       <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                         <div>
                                            {last_level === 4 ? (
                                             <Button onClick={() => onClickCompanyPendingTask(5)} className="button-dashboard-custom-submit">
                                             Company Financials
                                              </Button>
                                         ) : (
                                           <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                             Company Financials
                                           </span>
                                         )}
                                         </div>
                                       </div>
                                     </Accordion.Body>
                                   </Accordion.Item>  
                                   
                                  )}
                                  {last_level < 6 && (
                                    <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                            <div className="dashboard-card-sub-heading"> 
                                            <Button className="button-pending-dashboard">
                                              Action Required
                                            </Button>
                                          &nbsp;&nbsp;My Starlight Smart Reserve Application Summary
                                            </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                        <div>
                                           {last_level === 5 ? (
                                            <Button onClick={() => onClickCompanyPendingTask(6)} className="button-dashboard-custom-submit">
                                            My Starlight Smart Reserve Application Summary
                                             </Button>
                                        ) : (
                                          <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                            My Starlight Smart Reserve Application Summary
                                          </span>
                                        )}
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>  
                                    
                                  )}
                                   {last_level < 100 && (
                                        <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                                <div className="dashboard-card-sub-heading"> 
                                                <Button className="button-pending-dashboard">
                                                  Action Required
                                                </Button>
                                              &nbsp;&nbsp;Statement of Suitability
                                                </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                            <div>
                                               {last_level === 99  ? (
                                                <Button onClick={() => onClickCompanyPendingTask(7)} className="button-dashboard-custom-submit">
                                                Statement of Suitability
                                                 </Button>
                                            ) : (
                                              <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                                Statement of Suitability
                                              </span>
                                            )}
                                            </div>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item> 
                                  
                                  )} 
 
                               
                                  {last_level < 101 && (
                                      <Accordion.Item eventKey="6">
                                      <Accordion.Header>
                                              <div className="dashboard-card-sub-heading"> 
                                              <Button className="button-pending-dashboard">
                                                Action Required
                                              </Button>
                                            &nbsp;&nbsp;Starlight Smart Reserve Model Portfolio Description
                                              </div>
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div className="d-flex sub-content-dashboard pt-3 mx-2 border-bottom-task">
                                          <div>
                                             {last_level === 100  ? (
                                              <Button onClick={() => onClickCompanyPendingTask(8)} className="button-dashboard-custom-submit">
                                              Starlight Smart Reserve Model Portfolio Description
                                               </Button>
                                          ) : (
                                            <span className="text-muted" style={{ cursor: 'not-allowed' }}>
                                              Starlight Smart Reserve Model Portfolio Description
                                            </span>
                                          )}
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item> 
                                  )}
 
                       
                     </Accordion>
                      </CardBody>
                      </Card>
                      </div>
                    ) : (
                      <Row className='justify-content-center'>
                      <Card className='col-md-8 form-custom-card'>
                      <CardBody>
                    <Col md="12" className='sub-content-dashboard'>
                      
                            <div className='dashboard-card-sub-heading'>Your Starlight Smart Account application is currently under review.</div>
                            <div className='pt-3'>We will be in touch by email if we need anything else and will let you know once your account has been approved.</div>
                            <div className='pt-3'>Thank you for your patience!</div>
                            <div className='pt-3 dashboard-card-sub-heading'>The Starlight Wealth Team</div>
                    </Col>
                    </CardBody>
                    </Card>
                  </Row>
                    )}
                  </>
                )} */}
    </div>
   
  )
}

export default MyStarLight