import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Label, Input, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import animationData from '../../../content/lottie/upload_loader.json';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { uploadDocument, getCountriesList, updateEntity, updateEntityProperty, getCountryList, getState } from '../accounts/accounts.reducer';
import Lottie from 'lottie-react';
import { FileEarmarkMedicalFill, Trash } from 'react-bootstrap-icons';
import DropZone from 'app/modules/DropZone';
import ValidatedDatePickerField from 'app/modules/components/ValidatedDatePickerField';
import { useForm } from 'react-hook-form';
import { getCustomerDetails } from '../customer/customer.reducer';
import { getPendingTask } from 'app/modules/profile/profile.reducer';
import CommonToast from 'app/modules/components/CommonToast';

interface primaryDocumentDetailsProp {
  onFormComplete: (completed: boolean) => void;
}

export interface primaryDocumentDetailsRef {
  syncPrimaryDocumentDetailsWithServer: () => Promise<any>;
  savePrimaryDocumentDetailsWithServer: () => Promise<any>;
}
const PrimaryDocumentDetails: React.ForwardRefRenderFunction<primaryDocumentDetailsRef, primaryDocumentDetailsProp> = ({ onFormComplete }: primaryDocumentDetailsProp, ref) => {
  const dispatch = useAppDispatch();

  const customerDetails = useAppSelector(state => state.customer.entities);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [addressLine1, setAddressLine1] = useState<string>(mainAccountsEntity?.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState<string>(mainAccountsEntity?.addressLine2 || '');
  const [townOrCity, setTownorcity] = useState<string>(mainAccountsEntity?.townOrCity || '');
  const [state_or_province, setState_or_province] = useState<string>(mainAccountsEntity?.state_or_province || '');
  const [postcode, setPostcode] = useState<string>(mainAccountsEntity?.postcode || '');
  const [ppsNumber, setPpsnumber] = useState<string>(mainAccountsEntity?.ppsNumber || '');
  const [passport_number, setpassport_number] = useState<string>(mainAccountsEntity?.passport_number || '');
  const [country, setCountry] = useState<any>("Ireland");
  const [issued_country, setIssued_country] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [passport_expiry_date, setPassport_expiry_date] = useState<string>(mainAccountsEntity?.passport_expiry_date || '');
  const [countryOfBirth, setCountryOfBirth] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [citizenship, setCountryOfCitizenship] = useState<any>(mainAccountsEntity?.countryList?.find((data) => data.name === 'Ireland') || {});
  const [istaxresident, setIstaxresident] = useState<boolean>(mainAccountsEntity?.taxResidentInIreland ?? true);
  const [is_tax_resident_in_another_country, setIstaxresidentinAnotherCountry] = useState<boolean>(mainAccountsEntity?.is_tax_resident_in_another_country ?? false);
  const [employment_status, setEmployment_status] = useState<string>(mainAccountsEntity?.employment_status || 'EMPLOYED');
  const [occupation, setOccupation] = useState<string>(mainAccountsEntity?.occupation || '');
  const [employerName, setEmployerName] = useState<string>(mainAccountsEntity?.employerName || '');
  const [employerBusiness, setEmployerBusiness] = useState<string>(mainAccountsEntity?.employerBusiness || '');
  const [isapubliclylistedcompany, setIsapubliclylistedcompany] = useState<string>(false || mainAccountsEntity?.isapubliclylistedcompany);
  const [employerCompany, setEmployerCompany] = useState<string>(mainAccountsEntity?.employerCompany || '');
  type PassportExpiryErrorType = {
    passport_expiry_date?: string;
  };
  const [othertaxresidence, setOthertaxresidence] = useState<string>(mainAccountsEntity?.othertaxresidence || '');
  const [stateList, setStateList] = useState<string[]>([]);
  const [countryList, setCountryList] = useState<string[]>(mainAccountsEntity?.countryList || []);

  interface IValidationErrors {
    [key: string]: string;
  }

  useImperativeHandle(ref, () => ({
    syncPrimaryDocumentDetailsWithServer,
    savePrimaryDocumentDetailsWithServer
  }));

  useEffect(() => {
    dispatch(getState()).then((res) => {
      if (res.payload) {
        const stateData = res.payload.map((item: any) => item);
        setStateList(stateData);
      }
    });

  }, [dispatch]);

  const accessToken = localStorage.getItem('jhi-authenticationToken');
  useEffect(() => {

    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());
        if (res.payload) {
          const countryData = res.payload.map((item: any) => item);
          setCountryList(countryData);
        }
      });
    }

  }, [dispatch]);

  useEffect(() => {
    if (customerDetails) {

      const updatedProperties = {
        state_or_province: (state_or_province === '' || state_or_province == null)
          ? mainAccountsEntity?.state_or_province : state_or_province,
        country: (country === '' || country == null)
          ? (mainAccountsEntity?.country || 'Ireland') : country,
        issued_country: mainAccountsEntity?.issued_country ? mainAccountsEntity.issued_country : 'Ireland',
        employment_status: customerDetails?.employmentStatus || mainAccountsEntity.employment_status || 'EMPLOYED',

        birth_info: {
          countryOfBirth: mainAccountsEntity?.birth_info?.countryOfBirth || 'Ireland',
        },
        taxResidentInIreland: mainAccountsEntity?.taxResidentInIreland ?? true,

        passport_expiry_date: (passport_expiry_date === '' || passport_expiry_date == null)
          ? mainAccountsEntity?.passport_expiry_date : passport_expiry_date,
        employerAddress: {
          ...mainAccountsEntity?.employerAddress,
          country: (employerAddress?.country === '' || employerAddress?.country == null)
            ? (mainAccountsEntity?.employerAddress?.country || 'Ireland') : employerAddress?.country,
          state: (employerAddress?.state === '' || employerAddress?.state == null)
            ? (mainAccountsEntity?.employerAddress?.state || '') : employerAddress?.state,
        },
      };

      dispatch(updateEntityProperty(updatedProperties));
    }
  }, [customerDetails]);

  const [validationErrors, setValidationErrors] = useState<IValidationErrors>({});
  const [passportexpiryError, setPassportExpiryError] = useState<PassportExpiryErrorType>({});

  const [isUploadingOne, setIsUploadingOne] = useState(false);
  const [isUploadingTwo, setIsUploadingTwo] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<string>(mainAccountsEntity?.ppsNumberDoc || '');
  const [uploadedPassport, setUploadedPassport] = useState<string>(mainAccountsEntity?.passportDoc || '');
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [ppsError, setPpsError] = useState<string | null>(null);
  const [pendingTaskAccountId, setPendingTaskAccountId] = useState([])
  const accountsEntity = sessionStorage?.getItem('accountid');

  const {
    handleSubmit,
    register,
    setValue,
    trigger,
    getFieldState,
    formState: { isValid, touchedFields },
  } = useForm({ mode: 'onChange' });

  const findCountryNameByKey = (key: string | null | undefined): string => {
    const countryName: any = countryList.find((obj: any) => obj.name === key);
    return countryName ? countryName.name : '';
  };



  const syncPrimaryDocumentDetailsWithServer = (): Promise<any> => {

    const _errors: IValidationErrors = {};
    const minAllowedDate = getFifteenDaysFromToday();


    // if (!addressLine1) {
    //   _errors.addressLine1 = 'This field is mandatory';
    // }
    // if (!townOrCity) {
    //   _errors.townOrCity = 'This field is mandatory';
    // }
    // if (!postcode) {
    //   _errors.postcode = 'This field is mandatory';
    // }
    if (!ppsNumber) {
      _errors.ppsNumber = 'This field is mandatory';
    }
    if (!uploadedFile) {
      _errors.uploadedFile = 'This field is mandatory';
    }
    if (!passport_number) {
      _errors.passport_number = 'This field is mandatory';
    }
    if (!uploadedPassport) {
      _errors.uploadedPassport = 'This field is mandatory';
    }
    if (!citizenship) {
      _errors.citizenship = 'This field is mandatory';
    }
    if (!state_or_province) {
      _errors.state_or_province = 'This field is mandatory';
    }
    if (!passport_expiry_date) {
      _errors.passport_expiry_date = 'This field is mandatory';
    }
    if (!passport_expiry_date) {
      _errors.passport_expiry_date = 'This field is mandatory';
    } else if (new Date(passport_expiry_date) < new Date(minAllowedDate)) {
      _errors.passport_expiry_date = '';
    }
    if (istaxresident === false && !othertaxresidence) {
      _errors.othertaxresidence = 'This field is mandatory';
    }
    // if (employment_status === "EMPLOYED") {
    //   if (!occupation) {
    //     _errors.occupation = 'This field is mandatory';
    //   }
    //   if (!employerName) {
    //     _errors.employerName = 'This field is mandatory';
    //   }
    //   if (!employerBusiness) {
    //     _errors.employerBusiness = 'This field is mandatory';
    //   }
    //   if (!employerAddress?.city) {
    //     _errors.city = 'This field is mandatory';
    //   }
    //   if (!employerAddress?.state){
    //     _errors.state ='This field is mandatory';
    //   }
    //   if (!employerAddress?.postal_code) {
    //     _errors.postal_code = 'This field is mandatory';
    //   }
    //   if (!employerAddress?.street) {
    //     _errors.street = 'This field is mandatory';
    //   }
    // }

    if (Object.keys(_errors).length > 0) {
      setValidationErrors(_errors);
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);
      return null;
    }
    setValidationErrors({});
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 10 }));
  };

  const savePrimaryDocumentDetailsWithServer = (): Promise<any> => {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity }));
    }
  }

  const clearValidationError = (field: string) => {
    setValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
    setPassportExpiryError(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };
  const [employerAddress, setEmployerAddress] = useState({
    street: mainAccountsEntity?.employerAddress?.street,
    city: mainAccountsEntity?.employerAddress?.city,
    state: mainAccountsEntity?.employerAddress?.state,
    country: mainAccountsEntity?.employerAddress?.country || 'Ireland',
    postal_code: mainAccountsEntity?.employerAddress?.postal_code

  });

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value, id } = e.target;
    const lcl_errors: { [key: string]: string } = {};

    if (name === 'addressLine1') {
      setAddressLine1(value);
    } else if (name === 'addressLine2') {
      setAddressLine2(value);
    } else if (name === 'townOrCity') {
      setTownorcity(value);
    } else if (name === 'state_or_province') {
      setState_or_province(value);
    } else if (name === 'country') {
      setCountry(value);
    } else if (name === 'issued_country') {
      setIssued_country(value);
    } else if (name === 'postcode') {
      setPostcode(value.toUpperCase());
    } else if (name === 'passport_number') {
      setpassport_number(value);
    } else if (name === 'countryOfBirth') {
      setCountryOfBirth(value);
    } else if (name === 'countryOfCitizenship') {
      setCountryOfCitizenship(value);
    } else if (name === 'othertaxresidence') {
      setOthertaxresidence(value);
    }
    if (value) {
      clearValidationError(name);
    }
    dispatch(updateEntityProperty({
      [name]: value
    }));

  };


  useEffect(() => {
    dispatch(getPendingTask({}))
      .then((response: any) => {
        setPendingTaskAccountId(response?.payload?.data?.pending_accounts)
      })
  }, [])

  const handleFileUpload = async (file: any, docType: string) => {

    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    if (!accountsEntity && !pendingTaskAccountId?.[0]?.accountId) {
      return;

    }
    setIsUploadingOne(true);
    await dispatch(
      uploadDocument({
        accountId: accountsEntity || pendingTaskAccountId?.[0]?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadedFile(response.payload.data);

          // setUploadedFile({
          //   fileData: response.payload.data,
          //   fileName: file.name, 
          // });

          // Dispatch the updated entity property
          dispatch(updateEntityProperty({
            ppsNumberDoc: response.payload.data
          }));
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
          setIsUploadingOne(false);

        } else if (response?.payload?.response?.status === 400) {
          setIsUploadingOne(false);
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
          console.error('Response payload is undefined');

        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
        }

        setIsUploadingOne(false);

      })
      .catch((error) => {
        setIsUploadingOne(false);
        console.error(`Error uploading ${docType}:`, error);
        setToastMessage((prev) => 'Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
        setToastType('error');
        setToastVisible(true);
      });
  };
  const PPS_REGEX = /^\d{7}[A-Z]{1,2}$/;
  const handlePpsNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPpsnumber(value);

    if (PPS_REGEX.test(value)) {
      setPpsError('');
    } else {
      setPpsError('Invalid PPS number format. Expected format: 7 digits followed by 1 or 2 uppercase letters.');
    }
    dispatch(updateEntityProperty({ ppsNumber: value }));
    clearValidationError('ppsNumber');
  };
  //end
  const handleDeleteFile = () => {
    dispatch(updateEntityProperty({
      ppsNumberDoc: null,
    }))
    setUploadedFile(null);
  };

  const handlePassportFileUploaded = async (file: any, docType: string) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    if (!accountsEntity && !pendingTaskAccountId?.[0]?.accountId) {


      return;

    }
    setIsUploadingTwo(true);
    await dispatch(
      uploadDocument({
        accountId: accountsEntity || pendingTaskAccountId?.[0]?.accountId,
        file,
        docType,
      })
    )
      .then((response: any) => {
        if (response.payload.data) {
          setUploadedPassport(response.payload.data);
          // setUploadedPassport({
          //   fileData: response.payload.data,
          //   fileName: file.name, // Capture the file name
          // });

          const updatedJointDetails = { ...mainAccountsEntity, passportDoc: response.payload.data };


          dispatch(updateEntityProperty({
            passportDoc: response.payload.data,
          }));
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
          setIsUploadingTwo(false);

        } else if (response?.payload?.response?.status === 400) {
          console.log(response.payload.response.status);

          setIsUploadingTwo(false);
          console.error('Response payload is undefined');
          setToastMessage((prev) => 'Document upload failed!!');
          setToastType('error');
          setToastVisible(true);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
        }

        setIsUploadingTwo(false);

      })
      .catch((error) => {

        setIsUploadingTwo(false);
        console.error(`Error uploading ${docType}:`, error);
        setToastMessage((prev) => 'Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
        setToastType('error');
        setToastVisible(true);
      });
  }

  const handleDeletePassportFile = () => {
    dispatch(updateEntityProperty({
      passportDoc: null,
    }))
    setUploadedPassport(null);
  };

  const extractFileName = (fileData: string): string => {
    const prefix = 'SW_';
    const prefixIndex = fileData.indexOf(prefix);
    if (prefixIndex !== -1) {

      // Extract the part of the string after the prefix
      return fileData.substring(prefixIndex + prefix.length);
    }
    // Return the original string if prefix is not found
    return fileData;
  };

  const handleCountryIssued = (e: any) => {
    const { name, value } = e.target;
    setEmployerAddress(prevEmployerAddress => ({
      ...prevEmployerAddress,
      [name]: value
    }));

    if (name === 'postal_code') {

      dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, ['postal_code']: value } }));
    } else {
      dispatch(updateEntityProperty({ employerAddress: { ...mainAccountsEntity.employerAddress, [name]: value } }));
    }
    if (value) {
      clearValidationError(name);
    }

  }

  const handlePassportExpiry = (e: { target: { value: string } }) => {
    const lcl_passport_expiry_date = e.target.value;
    const today = new Date();
    const minAllowedDate = new Date(getFifteenDaysFromToday());
    const enteredDate = new Date(lcl_passport_expiry_date);

    let hasError = false;

    const enteredYear = enteredDate.getFullYear();
    if (enteredYear < today.getFullYear()) {
      setPassportExpiryError({
        passport_expiry_date: 'The year must not be in the past.',
      });
      hasError = true;
    } else {
      clearValidationError('passport_expiry_date');
    }

    if (!hasError && enteredDate < minAllowedDate) {
      setPassportExpiryError({
        passport_expiry_date: 'Passport expiry date must be at least 15 days from today.',
      });
      hasError = true;
    } else if (!hasError) {
      clearValidationError('passport_expiry_date');
    }

    setValue('passport_expiry_date', lcl_passport_expiry_date); // Set form value
    trigger('passport_expiry_date');  // Trigger form validation


    setPassport_expiry_date(lcl_passport_expiry_date);

    if (!hasError) {
      dispatch(updateEntityProperty({ passport_expiry_date: lcl_passport_expiry_date }));
    }
  };


  const [minDate, setMinDate] = useState('');

  useEffect(() => {
    setMinDate(getFifteenDaysFromToday());
  }, []);
  const getFifteenDaysFromToday = () => {
    const today = new Date();
    today.setDate(today.getDate() + 15);
    return today.toISOString().split('T')[0];
  };
  const handleCountryOfBirth = (e: any) => {
    setCountryOfBirth(e.target.value)
    dispatch(updateEntityProperty({
      birth_info: {
        countryOfBirth: e.target.value
      }
    }));
  };

  const handleCountryOfCitizenship = (e: any) => {
    setCountryOfCitizenship(e.target.value)
    dispatch(updateEntityProperty({
      citizenship: e.target.value
    }));
  };

  const handleTaxResidentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target;

    if (name === "taxResidentInIreland") {
      sessionStorage.setItem('taxResidentInIreland', JSON.stringify(checked));
      dispatch(updateEntityProperty({ ['taxResidentInIreland']: checked }));

      setIstaxresident(checked);
    }

    if (name === "is_tax_resident_in_another_country") {
      dispatch(updateEntityProperty({ ['is_tax_resident_in_another_country']: checked }));
      setIstaxresidentinAnotherCountry(checked);
    }
  };


  return (
    <div>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
      />
      <Row>
        <Col md="12" className="form-heading-custom pt-3 pb-3">
          Know Your Customer Verification
        </Col>
        <Row>

          <Col md="12">
          </Col>
          <Col md="12" className="from-sub-heading-custom pt-2">Social Security Details</Col>
          <Col md="12" className="pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> PPS Number</span>
              </Label>
              <Input
                type="text"
                name="ppsNumber"
                id="ppsNumber"
                className="custom-from-input"
                value={ppsNumber}
                defaultValue={mainAccountsEntity?.ppsNumber}
                onChange={e => handlePpsNumberChange(e)}
              />
              {ppsError && <div className="error-message" style={{ color: 'red' }}>{ppsError}</div>}

              {validationErrors.ppsNumber && <div className="text-danger">{validationErrors.ppsNumber}</div>}{' '}
            </FormGroup>
          </Col>

          <Col md="12">
            <FormGroup>
              <Col md="12" className="under-border-div mt-3 mb-3"></Col>
            </FormGroup>
            <Col md="12" className="from-sub-heading-custom">Tax Status</Col>
            <Col md="12" className='pt-3'>
              <FormGroup>
                <Label check className="label-custom-form-check-box">

                  <Input
                    type="checkbox"
                    name="taxResidentInIreland"
                    id="taxResidentInIreland"
                    className="input-account-custom-bgnone me-1"
                    checked={istaxresident}
                    // defaultChecked={mainAccountsEntity?.taxResidentInIreland}
                    defaultValue={mainAccountsEntity?.taxResidentInIreland}
                    onChange={handleTaxResidentChange}
                  />
                  I am only tax resident in Ireland.
                </Label>
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label check className="label-custom-form-check-box">

                  <Input
                    type="checkbox"
                    name="is_tax_resident_in_another_country"
                    id="is_tax_resident_in_another_country"
                    className="input-account-custom-bgnone me-1"
                    checked={is_tax_resident_in_another_country}
                    // defaultChecked={mainAccountsEntity?.taxResidentInIreland}
                    defaultValue={mainAccountsEntity?.is_tax_resident_in_another_country}
                    onChange={handleTaxResidentChange}
                  />
                  I am also a tax resident in another country.
                </Label>
              </FormGroup>
            </Col>
            {is_tax_resident_in_another_country === true ? (
              <Col md="12">
                <FormGroup>
                  <Label className="label-custom-form-input"><span>*</span> Other Tax Residence Details</Label>

                  <Input
                    type="text"
                    name="othertaxresidence"
                    id="othertaxresidence"
                    className="custom-from-input"
                    value={othertaxresidence}
                    defaultValue={mainAccountsEntity?.othertaxresidence}
                    onChange={handleValueChange}
                  />
                  <span className="text-black">
                    Please list any other countries where you are tax resident, including your Tax Identification Number for that country e.g. United Kingdom
                  </span>
                  {validationErrors?.othertaxresidence && <div className="text-danger">{validationErrors?.othertaxresidence}</div>}

                </FormGroup>
              </Col>
            ) : null}
          </Col>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
          <Col md="12" className="from-sub-heading-custom ">Proof of Identity (POI) - Passport Details</Col>
          <div className="form-text-content pt-2">
            Please upload a recent passport document in PNG, JPG, JPEG, or PDF file format. This should include the main page and the signature page, which must be signed.
          </div>
          <Col md="12" className="pt-3">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Passport Number</span>
              </Label>
              <Input
                type="text"
                name="passport_number"
                id="passport_number"
                className="custom-from-input"
                value={passport_number}
                defaultValue={mainAccountsEntity?.passport_number}
                onChange={e => handleValueChange(e)}
              />
              {validationErrors.passport_number && <div className="text-danger">{validationErrors.passport_number}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="12" className="">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span> Country Issued
              </Label>
              <Input
                type="select"
                name="issued_country"
                id="issued_country"
                className="custom-from-input"
                value={mainAccountsEntity?.issued_country}
                onChange={handleValueChange}
              >
                <> <option value={mainAccountsEntity?.issued_country}>{mainAccountsEntity?.issued_country || "Ireland"}</option></>
                {countryList && countryList?.filter((obj: any) => obj.name !== 'Ireland')?.map((item: any, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </Input>


              {validationErrors && !issued_country && <div className="text-danger">{validationErrors.issued_country}</div>}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Passport Expiry Date</span>
              </Label>

              <Form className="pt-2">
                {/* <ValidatedField
          name="PassportExpiry"
          required
          className="datefield-expiry-style"
          type="date"
          data-cy="passport expiry date"
          value={passport_expiry_date}
          onChange={handlePassportExpiry} 
          defaultValue={mainAccountsEntity?.passport_expiry_date}
          min={minDate}
          // onKeyDown={(e) => e.preventDefault()}
        /> */}

                <ValidatedDatePickerField
                  onChange={(date) => {
                    handlePassportExpiry({ target: { value: date } }); // Properly invoke the function
                  }}
                  defaultdate={mainAccountsEntity?.passport_expiry_date || passport_expiry_date}
                  getFieldState={getFieldState}
                  register={register}
                />
              </Form>
              {passportexpiryError && passportexpiryError.passport_expiry_date && (
                <p className="text-danger">{passportexpiryError.passport_expiry_date}</p>
              )}
              {validationErrors.passport_expiry_date && <div className="text-danger">{validationErrors.passport_expiry_date}</div>}{' '}
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label className="label-custom-form-input">
                <span>*</span>
                <span> Passport Document Upload</span>
              </Label>

              {isUploadingTwo ? (
                <div style={{ width: '50px' }}>
                  <Lottie animationData={animationData} loop autoplay />
                  <span style={{ color: '#607980' }}>Uploading...</span>
                </div>
              ) : (
                <>
                  {mainAccountsEntity?.passportDoc || uploadedPassport ? (
                    <div className="custom-from-input-upload upload-button-new-style">

                      <div className='uploaded-file-style'>
                        <div className="d-flex text-uploaded-document cursor-pointer  strong" style={{ height: '30px' }}>
                          <div className='align-content-center'>
                            <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '20px' }} />
                          </div>
                          <div className="pt-1" style={{ fontSize: '16px' }}>{extractFileName(mainAccountsEntity?.passportDoc)}&nbsp;&nbsp;</div>
                        </div>
                        <div className="align-content-center p-0" onClick={handleDeletePassportFile} style={{ cursor: 'pointer', height: '30px' }}>
                          <Trash className="trash-btn" style={{ fontSize: '16px' }} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>

                      <DropZone onFileUploaded={handlePassportFileUploaded} docType={'PASSPORT'} />
                      {validationErrors.uploadedPassport && <div className="text-danger">{validationErrors.uploadedPassport}</div>}{' '}

                    </>

                  )}
                </>
              )}
            </FormGroup>
          </Col>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>

          <Col md="12">
            <Col md="12" className="from-sub-heading-custom">Proof of Address (POA) - Driver's License (Preferred) or Bank Statement/Utility Bill</Col>
            <div className="form-text-content pt-2">
              The printed address on your proof of address document needs to match the address provided at the beginning of the application. If your Drivers Licence matches this address, please use this as your proof of address. If not please upload a recent bankstatement or utility bill.
            </div>
            <Label className="label-custom-form-input pt-3">
              <span >*</span>
              <span> Proof of Address Upload</span>
            </Label>
            {isUploadingOne ? (
              <div style={{ width: '50px' }}>
                <Lottie animationData={animationData} loop autoplay style={{ color: '#FF0000' }} />
                <span style={{ color: '#607980' }}>Uploading...</span>
              </div>
            ) : (
              <>
                {mainAccountsEntity?.ppsNumberDoc || uploadedFile ? (
                  <div className="custom-from-input-upload upload-button-new-style">
                    <div className='uploaded-file-style'>
                      <div className="d-flex text-uploaded-document cursor-pointer strong" style={{ height: '30px' }}>
                        <div className='align-content-center'>
                          <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '20px' }} />
                        </div>
                        <div className="pt-1" style={{ fontSize: '16px' }}>{extractFileName(mainAccountsEntity.ppsNumberDoc)} &nbsp;&nbsp;</div>
                      </div>
                      <div className='align-content-center p-0' onClick={handleDeleteFile} style={{ cursor: 'pointer', height: '30px' }}>
                        <Trash className="trash-btn" style={{ fontSize: '16px' }} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <DropZone onFileUploaded={handleFileUpload} docType={'PPC'} />
                    {validationErrors.uploadedFile && <div className="text-danger">{validationErrors.uploadedFile}</div>}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default forwardRef(PrimaryDocumentDetails);
