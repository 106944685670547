 
import Lottie from 'lottie-react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import animationData from '../../../content/lottie/upload_loader.json';
import { FileEarmarkMedicalFill, Trash } from 'react-bootstrap-icons';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Dropzone from 'app/modules/DropZone';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setCountriesList, updateEntityProperty, uploadDocument } from '../accounts/accounts.reducer';
import { toast } from 'react-toastify';
import CommonToast from 'app/modules/components/CommonToast';
import { getCountryList, updateEntity } from './company-accounts-reducer';
import { getCustomerDetails } from '../customer/customer.reducer';
interface CompanyDetailsProps { onFormComplete: (completed: boolean) => void; }
export interface companyDetailsRef {
  syncCompanyDetailsWithServer: () => Promise<any>
}
 
const CompanyDetails: React.ForwardRefRenderFunction<companyDetailsRef, CompanyDetailsProps> = ({ onFormComplete }: CompanyDetailsProps, ref) => {
 
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const [secondvalidationErrors, setsecondValidationErrors] = useState<IValidationErrors>({});
 
 
  const [inputValues, setInputValues] = useState<{ [key: string]: any }>({ ...mainAccountsEntity, ...mainAccountsEntity.company_details } || {});
  const countryList: any = mainAccountsEntity?.countryList;
  const stateList: any = mainAccountsEntity?.statesList;
  const dispatch = useAppDispatch();
  const accountsEntity = sessionStorage?.getItem('accountid');
  const [isUploadingOne, setIsUploadingOne] = useState(false);
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [proof_of_company_doc, setproof_of_company_doc] = useState<string | null>(mainAccountsEntity?.company_details?.proof_of_company_doc || null);
  const [tax_residency, setTaxResidency] = useState(
    mainAccountsEntity?.company_details?.tax_residency ||true );
 
    console.log("mainAccountsEntity", mainAccountsEntity?.company_details?.tax_residency );
   
 
 
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [selectedOptionLIE, setSelectedOptionLIE] = useState('');
 
  const findCountryNameByKey = (key: string | null | undefined): string => {
    const country: any = countryList?.find((obj: any) => obj.name === key);
    return country ? country.name : '';
  };
  const findStateNameByKey = (key: string | null | undefined): string => {
    const state: any = stateList.find((obj: any) => obj.name === key);
 
 
    return state ? state.name : '';
  };
 
 
  useEffect(() => {  
  const updatedMainAccountsEntity = {
    ...mainAccountsEntity,
    state_or_province:mainAccountsEntity?.state_or_province  ,    
    company_details: {
      ...mainAccountsEntity.company_details,
      tax_residency: mainAccountsEntity?.company_details?.tax_residency !== undefined
      ? mainAccountsEntity.company_details.tax_residency
      : true,
      is_lei: inputValues?.is_lei ?? (mainAccountsEntity?.company_details?.is_lei || true),
      fatca_classification: inputValues?.fatca_classification 
    },
  };
 
  dispatch(updateEntityProperty(updatedMainAccountsEntity));
 
}, [dispatch,tax_residency,mainAccountsEntity?.company_details?.tax_residency ,mainAccountsEntity?.state_or_province,inputValues.is_lei]);
 
  const handleFileUploaded = (file: File, docType: string) => {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (!allowedFileTypes.includes(file.type)) {
      setToastMessage('Unsupported file format');
      setToastType('error');
      setToastVisible(true);
      return;
    }
    if (!mainAccountsEntity?.accountId) {
      console.error('No accountsEntity found');
      return;
    }
 
    dispatch(
      uploadDocument({
        accountId: mainAccountsEntity?.accountId,
        file,
        docType,
      }),
    )
      .then((response: any) => {
        if (response.payload.data) {
          setproof_of_company_doc(response.payload.data);
          dispatch(updateEntityProperty({
            ['company_details']: {
              ...mainAccountsEntity.company_details,
              ['proof_of_company_doc']: response.payload.data
            }
          }));
          setToastMessage((prev) => 'Uploaded Successfully');
          setToastType('success');
          setToastVisible(true);
        }else if (response?.payload?.response?.status===400){
          console.error('Response payload is undefined');
          setToastMessage((prev) => 'Document upload failed !!');
          setToastType('error');
          setToastVisible(true);
          setIsUploadingOne(false);
        }
        else {
          console.error('Unexpected response format or error:', response);
          setToastMessage('Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
        }
    
        setIsUploadingOne(false);
      })
      .catch(error => {
        console.error(`Error uploading ${docType}:`, error);
        setToastMessage((prev) => 'Please save the document as a smaller file size  (less than 2MB) and try and upload again.');
          setToastType('error');
          setToastVisible(true);
          setIsUploadingOne(false);
      });
 
    setIsUploadingOne(true);
    setTimeout(() => {
      setIsUploadingOne(false);
    }, 2000);
  };
 
  const handleDeleteFile = () => {
    setproof_of_company_doc(null);
    setIsUploadingOne(false);
  };
 
  interface IValidationErrors {
    [key: string]: string;
  }
  const handleOptionChangeLIE = event => {
    setSelectedOptionLIE(event.target.value);
  };
  useImperativeHandle(ref, () => ({
    syncCompanyDetailsWithServer,
  }));
 
  const syncCompanyDetailsWithServer = (): Promise<any> => {
    const _errors: IValidationErrors = {};
 
    if (!inputValues.company_name) {
      _errors.company_name = 'This field is mandatory';
    }
 
    if (!inputValues.registration_number) {
      _errors.registration_number = 'This field is mandatory';
    }
    if (!inputValues.accountHolderMobileNumber) {
      _errors.accountHolderMobileNumber = 'This field is mandatory';
    }
 
    if (!inputValues.townOrCity) {
      _errors.townOrCity = 'This field is mandatory';
    }
    if (!proof_of_company_doc) {
      _errors.proof_of_company_doc = 'This field is mandatory';
    }
    if (!inputValues.state_or_province) {
      _errors.state_or_province = 'This field is mandatory';
    }
    if (!inputValues.postcode) {
      _errors.postcode = 'This field is mandatory';
    }
    if (!inputValues.hasOwnProperty('is_lei') && inputValues.is_lei === '') {
      _errors.is_lei = 'This field is mandatory';
    }
 
    if (inputValues.hasOwnProperty('is_lei') && inputValues.is_lei === false && !inputValues.lei) {
      _errors.lei = 'This field is mandatory';
    }
    if (!inputValues.street_or_province) {
      _errors.street_or_province = 'This field is mandatory';
    }
 
    if (!inputValues.description_of_business_activities) {
      _errors.description_of_business_activities = 'This field is mandatory';
    }
    if (!inputValues.tax_registration_number && !mainAccountsEntity?.company_details?.tax_residency ===false) {
      _errors.tax_registration_number = 'This field is mandatory';
    }
 
    if (Object.keys(_errors).length > 0) {
      setsecondValidationErrors(_errors);
 
      setToastMessage((prev) => 'Please fill all the mandatory fields');
      setToastType('error');
      setToastVisible(true);
 
      return null;
    }
    return dispatch(updateEntity({
      ...mainAccountsEntity,
      is_company_account: true,
      companyAccount: true,
      last_level: 2
    }));
  }
 
  const handleChangeRevenue = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target; // Get the value directly from the event target
 
 
    let taxResidencyValue
    if(value ==="true"){
      taxResidencyValue  =true;
    }else{
      taxResidencyValue  =false;
    }
   // Convert to boolean
 
    const updatedMainAccount = {
      ...mainAccountsEntity,
      company_details: {
        ...mainAccountsEntity.company_details,
        tax_residency: taxResidencyValue, // Use the converted boolean value
      },
    };
 
    setTaxResidency(taxResidencyValue); // Update state
    dispatch(updateEntityProperty(updatedMainAccount)); // Dispatch updated entity
  };
 
  const clearValidationError = (field: string) => {
    setsecondValidationErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };
 
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
   
    const { name: inputName, value } = e.target;
   
    // const regex = /^\+3538\d{0,8}$/;
    const regex = /^\+\d{8,12}$/;
    setInputValues(prev => ({ ...prev, [inputName]: value }));
 
   
 
    const newErrors = { ...secondvalidationErrors };
 
    if (inputName === 'accountHolderMobileNumber') {
      if (value.length === 0) {
        newErrors.accountHolderMobileNumber = '';
      } else if (!regex.test(value)) {
        // newErrors.accountHolderMobileNumber = 'Please enter a valid mobile number starting with +3538 and followed by 8 digits.';
         newErrors.accountHolderMobileNumber = 'Please enter a valid phone number starting with the country code (e.g., +1) followed by 8 to 12 digits.'
      } else {
        newErrors.accountHolderMobileNumber = '';
      }
    } else if (['street_or_province', 'postcode', 'townOrCity', 'state', 'country', 'registration_number', 'trading_name', 'company_name', 'state_or_province', 'description', 'legal_entity_identifier', 'fatca_classification', 'tax_residency', 'description_of_business_activities', 'tax_registration_number', 'is_lei'].includes(inputName)) {
      if (value.length > 0) {
        newErrors[inputName] = '';
      }
    }
    setsecondValidationErrors(newErrors);
    if (['street', 'townorcity', 'state', 'country', 'registration_number', 'trading_name', 'company_name','description', 'legal_entity_identifier', 'fatca_classification', 'tax_residency', 'description_of_business_activities', 'tax_registration_number', 'is_lei','lei'].includes(inputName)) {
      const boolValue = (typeof value === 'string' && value.toUpperCase() === 'Yes'.toUpperCase()) ? true : false;
      const newProp: any = { ...mainAccountsEntity.company_details, [inputName]: inputName === 'is_lei' ? boolValue : value };
      if (inputName === 'is_lei') {
        setInputValues(prev => ({ ...prev, [inputName]: boolValue }));
      }
      if (value) {
        clearValidationError(inputName);
      }
      // Dispatch action to update Redux state without resetting other parts
      dispatch(updateEntityProperty({ ['company_details']: newProp }));
    } else {
      dispatch(updateEntityProperty({ [inputName]: value }));
    }
   
  };
 
  // const handleKeyDown = event => {
  //   const allowedKeys = /[0-9+\b]/;
  //   if (!allowedKeys.test(event.key) && event.keyCode !== 8) {
  //     event.preventDefault();
  //   }
  //   if ((event.key === 'Backspace' || event.keyCode === 8) && event.target.selectionStart <= 5) {
  //     event.preventDefault();
  //   }
  // };
 
  return (
    <>
      <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
        useWhiteOverlay={false}  
        
 
      />
        <Col md="12 pt-3" className="form-heading-custom">
          Company Details
        </Col>

        <Col md="12" className='pt-3'>
          <FormGroup>
            <Label className="label-custom-form-input">
              * Company Name
            <div className="sub-label-custom-form-input">
              The Full Registered Company Name
            </div>
            </Label>
 
            <Input
              type="text"
              name="company_name"
              id="company_name"
              value={inputValues.company_name}
              defaultValue={mainAccountsEntity?.company_details?.company_name}
              onChange={e => handleValueChange(e)}
              className="custom-from-input"
            />
            {secondvalidationErrors.company_name && <div className="text-danger">{secondvalidationErrors.company_name}</div>}{' '}
          </FormGroup>
        </Col>
 
        <Col md="12">
          <FormGroup>
            <Label className="label-custom-form-input">
              Trading Name
            <div className="sub-label-custom-form-input">
              If Different to the Registered Company Name
            </div>
            </Label>
            <Input
              type="text"
              name="trading_name"
              id="trading_name"
              defaultValue={mainAccountsEntity?.company_details?.trading_name}
 
              value={inputValues.trading_name}
              onChange={handleValueChange}
              className="custom-from-input"
            />
            {/* {secondvalidationErrors.trading_name && <div className="text-danger">{secondvalidationErrors.trading_name}</div>}{' '} */}
          </FormGroup>
        </Col>
        <Col md="12" >
          <FormGroup>
            <Label className="label-custom-form-input">
              * Company Registration Number
            </Label>
            <Input
              type="text"
              name="registration_number"
              id="registration_number"
              defaultValue={mainAccountsEntity?.company_details?.registration_number}
 
              value={inputValues.registration_number}
              onChange={handleValueChange}
              className="custom-from-input"
            />
            {secondvalidationErrors.registration_number && <div className="text-danger">{secondvalidationErrors.registration_number}</div>}{' '}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-custom-form-input">
              <span>* </span>
              <span>Phone Number</span>
            </Label>
            <Input
              type="tel"
              name="accountHolderMobileNumber"
              id="accountHolderMobileNumber"
              value={inputValues.accountHolderMobileNumber}
              //defaultValue={mainAccountsEntity?.accountHolderMobileNumber}
              defaultValue="+353"
              maxLength={13}
              // onKeyDown={handleKeyDown}
              onChange={e => handleValueChange(e)}
              className="custom-from-input"
            />
            {secondvalidationErrors && <div className="text-danger">{secondvalidationErrors.accountHolderMobileNumber}</div>}{' '}
          </FormGroup>
        </Col>
 
        <Col md="12" className="from-sub-heading-custom pt-2 pb-2">
              Company Registered Address
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-custom-form-input">
            * Street
            </Label>
            <Input
              type="text"
              name="street_or_province"
              id="street_or_province"
              className="custom-from-input"
              defaultValue={mainAccountsEntity?.street_or_province}
 
              value={inputValues.street_or_province}
              onChange={e => handleValueChange(e)}
            />
            {secondvalidationErrors.street_or_province && <div className="text-danger">{secondvalidationErrors.street_or_province}</div>}{' '}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup >
            <Label className="label-custom-form-input">
              <span >* </span>
              <span>Town / City</span>
            </Label>
            <Input
              type="text"
              name="townOrCity"
              id="townOrCity"
              className="custom-from-input"
              defaultValue={mainAccountsEntity?.townOrCity}
 
              value={inputValues.townOrCity}
              onChange={e => handleValueChange(e)}
            />
            {secondvalidationErrors.townOrCity && <div className="text-danger">{secondvalidationErrors.townOrCity}</div>}
          </FormGroup>
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-custom-form-input">
              <span >* </span>
              County
            </Label>
            <Input
              type="select"
              name="state_or_province"
              id="state_or_province"
              className="custom-from-input"
              defaultValue={mainAccountsEntity?.state_or_province  }
              value={inputValues.state_or_province}
              onChange={e => handleValueChange(e)}
            >
              <> <option value=""> </option></>
              {stateList && stateList.map((item: any, index) => (
                <option key={index} value={item.name}>{item.name}</option>
              ))}
            </Input>
            {secondvalidationErrors.state_or_province && <div className="text-danger">{secondvalidationErrors.state_or_province}</div>}
 
          </FormGroup>
        </Col>
 
 
 
        <Col md="12">
        <FormGroup>
  <Label className="label-custom-form-input">* Country</Label>
  <Input
    type="text"
    name="country"
    id="country"
    className="custom-from-input-readonly"
    value="Ireland"
    readOnly
  />
</FormGroup>
 
        </Col>
        <Col md="12">
          <FormGroup>
            <Label className="label-custom-form-input">* Post Code</Label>
            <Input
              type="text"
              name="postcode"
              id="postcode"
              className="custom-from-input"
              value={inputValues.postcode}
              // onChange={e => handleValueChange(e)}
              onChange={e => {
                const uppercaseValue = e.target.value.toUpperCase(); 
                const name = e.target.name;
                handleValueChange({ ...e, target: { ...e.target, value: uppercaseValue, name } }); 
              }}
              style={{ textTransform: 'uppercase' }}
            />
            {secondvalidationErrors.postcode && <div className="text-danger">{secondvalidationErrors.postcode}</div>}{' '}
          </FormGroup>
        </Col>
        <Col md="12">
            <Label className="label-custom-form-input">
              <span>* </span>
              <span>Proof of Company Address Upload</span>
 
            </Label>
 
              {isUploadingOne ? (
                <div style={{ width: '50px' }}>
                  <Lottie animationData={animationData} loop autoplay />
                  <span style={{ color: '#607980' }}>Uploading...</span>
                </div>
              ) : (
                <>
                  {proof_of_company_doc ? (
                    <div className="custom-from-input-upload upload-button-new-style">
                      <ul style={{ listStyleType: 'none' }}>
                        <li className="text-light iconxs">
                          <div className='uploaded-file-style'>
                            <span className="text-danger cursor-pointer ms-2 strong gap-5">
                              <FileEarmarkMedicalFill className="text-uploaded-document" style={{ fontSize: '40px' }} /> <span> &nbsp;</span>{' '}
                              <span className="text-uploaded-document">{'Company Proof'}</span>
                            </span>
                            <div className="trash-btn" onClick={handleDeleteFile} style={{ cursor: 'pointer' }}>
                              <Trash className="trash-btn" />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <>
                      <Dropzone onFileUploaded={handleFileUploaded} docType={'Company Proof'} />
                      {secondvalidationErrors.proof_of_company_doc && <div className="text-danger">{secondvalidationErrors.proof_of_company_doc}</div>}
                  
                      <div className="sub-label-custom-form-input">
                        Please upload a recent Company bank statement or utility bill in PNG, JPG, JPEG, or PDF file format. This should match the above Company registered address details
                    </div>
                  </>
                  
                  )}
                </>
              )}
        </Col>
        <FormGroup>
          <Col md="12" className="under-border-div pt-3 pb-2"></Col>
        </FormGroup>
        <Col md="12">
          <FormGroup>
            <Label className="label-account-class"></Label>
 
            <Label className="label-custom-form-input">
              <div>* Description of Business Activities</div>
            <div className="sub-label-custom-form-input">
              Please provide a short description of your business
            </div>
            </Label>
            <Input
              type="text"
              name="description_of_business_activities"
              id="description_of_business_activities"
              value={inputValues.description_of_business_activities}
              onChange={handleValueChange}
              className="custom-from-input"
            />
            {secondvalidationErrors.description_of_business_activities && (
              <div className="text-danger">{secondvalidationErrors.description_of_business_activities}</div>
            )}{' '}
          </FormGroup>
        </Col>
        <Col md="12" className="pt-2">
          <FormGroup>
            <Label className="label-custom-form-input">
              <span>Legal Entity Identifier (LEI)</span>
              <div className="sub-label-custom-form-input">
               If your Company has an LEI please provide these details. If your Company does not have an LEI, It 
               is a condition of the account application process that Interactive Brokers apply for an LEI on your behalf. Without an LEI you will not be able to make 
               investments in your account. For more information on LEIs, please click
                &nbsp;
                <a href="https://starwealth-dev.azureedge.net/development-storage/starlight-documents/Legal%20Entity%20Identifiers-Starlight101.pdf" target="_blank" style={{ color: '#597177', }} rel="noreferrer">
                  here
                </a>
              </div>
            </Label>
            <div className="">
              <Input
                type="text"
                name="lei"
                id="lei"
                className="custom-from-input"
                value={inputValues.lei}
                defaultValue={mainAccountsEntity.lei}
                onChange={handleValueChange}
              />
            </div>
            {secondvalidationErrors.lei && <div className="text-danger">{secondvalidationErrors.lei}</div>}{' '}
          </FormGroup>
      </Col>
      <Col md="12">
        <FormGroup>
            <Label className="label-custom-form-input">
            If your company does not have an LEI,&nbsp;please confirm your instruction that
            Interactive Brokers apply for an LEI on your behalf.
            </Label> 
          <div className="d-flex pt-2">
            <div className='pe-3'>
              <label className='label-custom-form-check-box'>
                <Input
                  type="radio"
                  name="is_lei"
                  id="is_lei"
                  value="Yes"
                  className='custom-radio-input'
                  checked={inputValues.hasOwnProperty('is_lei') ? inputValues.is_lei === true : true}
                  onChange={handleValueChange}
                />
                <span className='px-1'>Yes</span> 
              </label>
            </div>
            <div>
              <label className='label-custom-form-check-box'>
                <Input
                  type="radio"
                  name="is_lei"
                  value="No"
                  className='custom-radio-input'
                  checked={inputValues.is_lei === false}
                  onChange={handleValueChange}
                />
                <span className='px-1'>No</span>
              </label>
            </div>
          </div>
        </FormGroup>
      </Col>
 
      <Col md="12">
      <FormGroup>
          <Label className="label-custom-form-input">
          <span>* </span>
            <span>Tax Residency</span>
          <div className="sub-label-custom-form-input">
            Is the company Tax Resident in Ireland
          </div>
          </Label>
          <div className="d-flex pt-2">
          <div className='pe-3'>
              <label className='label-custom-form-check-box'>
                <Input
                  type="radio"
                  name="tax_residency"
                  value="true"
                  className='custom-radio-input'
                  defaultValue={inputValues?.tax_residency}
                  checked={ mainAccountsEntity?.company_details?.tax_residency ===true}
                  onChange={handleChangeRevenue}
                />
                <span className='px-1'>Yes</span> 
              </label>
            </div>
            <div>
              <label className='label-custom-form-check-box'>
                <Input
                  type="radio"
                  name="tax_residency"
                  value="false"
                  className='custom-radio-input'
                  checked={ mainAccountsEntity?.company_details?.tax_residency ===false}
                  onChange={handleChangeRevenue}
                />
                <span className='px-1'>No</span>
              </label>
              </div>
            </div>
              </FormGroup>
</Col>
 
 
 
 
 
 
    {mainAccountsEntity?.company_details?.tax_residency === true && (
      <>
          <Col md="12">
            <FormGroup>
          <Label className="label-custom-form-input">
            <span>* </span>
            <span>Company Tax Registration Number</span>
          </Label>
          <Input
            type="text"
            name="tax_registration_number"
            id="tax_registration_number"
            value={inputValues.tax_registration_number}
            onChange={handleValueChange}
            className="custom-from-input"
         
          />
       {secondvalidationErrors.tax_registration_number && <div className="text-danger">{secondvalidationErrors.tax_registration_number}</div>}{' '}
       </FormGroup>
        </Col>
 
          <Col md="12" >
          <FormGroup>
          <Label className="label-custom-form-input">
            <span>FATCA Classification (if known)</span><br />
                <div className="sub-label-custom-form-input">
                  For more information on how you determine your FACTA Classification,please click here
                </div>

          </Label>
          <Input
            type="select"
            name="fatca_classification"
            id="fatca_classification"
            value={inputValues.fatca_classification}
            onChange={handleValueChange}
            className="custom-from-input"
            >
          <option>Do not know</option>
          <option>Active NFFE(Non-Financial-Foreign Entity)</option>
          <option>Passive NFFE(Non-Financial-Foreign Entity)</option>
          <option>Exempt Retirement Plan</option>
          <option>Nonprofit Organization</option>
          <option>FFI(Foreign Financial Institution)</option>
          <option>Other-Please specify</option>
          </Input>
          {/* {validationErrror && !companyDetails.fatca && <div className="text-danger">{validationErrror}</div>} */}
            </FormGroup>
          </Col>
      </>
    )}

    </>
  );
};
 
export default forwardRef(CompanyDetails);
 
 
 