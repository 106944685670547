import React, { useState, memo } from "react";
import { ValidatedField } from "react-jhipster";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
} from "reactstrap";
import { FieldError, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "app/config/store";
import { resetPassword } from "app/modules/account/register/register.reducer";
import CommonToast from "../CommonToast";
import "./modalStyle.scss";
import CustomInput from "../CustomInput";
import AuthRules from "./rules";

interface ResetPasswordTypes {
  showSignInModal: boolean;
  handleClose: (arg: boolean) => void;
}

interface formTypes {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

const ResetPasswordModal = ({
  showSignInModal,
  handleClose,
}: ResetPasswordTypes) => {
    
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({ mode: "onTouched" });

  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"success" | "error">("success");
  const [Username, setUsername] = useState("");
  const [toastVisible, setToastVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleResetPassword = async (data: formTypes) => {
    const { newPassword, confirmPassword, currentPassword } = data;
    if (!isValid) {
      return;
    }

    if (newPassword === currentPassword) {
      setErrorMessage("Current Password and New Password cannot be the same!");
      return;
    }

    if (confirmPassword && confirmPassword !== newPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    const payLoad = {
      username: Username,
      current_password: currentPassword,
      password: newPassword,
      event: "RESET_EVENT",
    };

    try {
      const response: any = await dispatch(resetPassword(payLoad));
      if (
        typeof response?.payload?.data === "string" &&
        response.payload.data.toLowerCase().trim() ===
          "password updated successfully"
      ) {
        setToastMessage(response?.payload?.data);
        setToastType("success");
        setToastVisible(true);
        handleClose(false);
      } else {
        setToastMessage(response?.payload?.data??"Password not updated");
        setToastType("error");
        setToastVisible(true);
      }
    } catch (error) {
      console.error("Error in forgot password flow:", error);
    }
  };

  const handleToastClose = () => {
    setToastVisible(false);
    if (toastType === "success") {
      navigate("/dashboard");
      window.location.reload();
    }
  };

  return (
    <>
      <CommonToast
        visible={toastVisible}
        onClose={() => handleToastClose()}
        title={toastType === "success" ? "Success" : "Error"}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
      />
      <Modal isOpen={showSignInModal} className="mt-4">
        <Form onSubmit={handleSubmit(handleResetPassword)}>
          <Row
            style={{
              backgroundImage:
                "url('../../../content/images/starlight-login.webp')",
              backgroundPosition: "center top",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              // border: "1px solid white",
            }}
            className="justify-content-center"
          >
             
            <Col md="10">
                
              <ModalBody>
                <Row>
                <Col className="text-center" xs={12} md={12}>    
              <img className="img-fluid logo-dashboard" src="../../../../../content/images/new_logo_ash_bg_color.svg" />
             </Col>
                  <Col md="12" className="mt-2">
                    <h5>Reset Password</h5>
                  </Col>
                  <Col md="12" className="mt-2">
                    <ValidatedField
                      name="Email"
                      label="Email"
                      placeholder="Enter your Email"
                      required
                      autoFocus
                      onChange={(e) => setUsername(e.target.value)}
                      className="validated-field1"
                      data-cy="username"
                      validate={{ required: "Email cannot be empty!" }}
                      style={{ padding: "3px 12px" }}
                      error={errors.username as FieldError}
                    />
                    <CustomInput
                      inputName="currentPassword"
                      inputType="password"
                      className="validated-field1"
                      placeholder="Enter your current password"
                      label="Current Password"
                      editable={true}
                      control={control}
                      rules={AuthRules.currentPassword}
                    />

                    <CustomInput
                      inputName="newPassword"
                      inputType="password"
                      className="validated-field1"
                      placeholder="Enter your new password"
                      label="New Password"
                      onInputChange={() => {
                        errorMessage && setErrorMessage(null);
                      }}
                      editable={true}
                      control={control}
                      rules={AuthRules.password}
                    />
                    <div className="col">
                      <ul className="text-start terms-and-conditions">
                        <li> Password must be at least 8 characters long</li>

                        <li>
                          Password must contain at least one uppercase letter,
                          one lowercase letter, one number, and one special
                          character
                        </li>
                      </ul>
                    </div>
                    <CustomInput
                      inputName="confirmPassword"
                      inputType="password"
                      className="validated-field1"
                      placeholder="Confirm your new password"
                      label="Confirm New Password"
                      onInputChange={() => {
                        errorMessage && setErrorMessage(null);
                      }}
                      editable={true}
                      control={control}
                    />

                    {errorMessage && (
                      <p className="errorText">{errorMessage}</p>
                    )}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="d-flex flex-column align-items-center">
                <Row>
                  <Col className="d-flex">
                    <ValidatedField
                      name="rememberMe"
                      type="checkbox"
                      check
                      style={{fontSize:"16px"}}
                      id="rememberMeCheckbox"
                      value={isCheckboxChecked}
                      required
                      onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                    />
                    <label
                      className="terms-and-conditions"
                      htmlFor="rememberMeCheckbox"
                    >
                      I agree to the Starlight Wealth{" "}
                      <a
                        href="../../../content/policies/starlight_wealth_terms_of_business.pdf"
                        className="terms-and-conditions"
                        target="_blank"
                      >
                        Terms of Business
                      </a>
                      ,{" "}
                      <a
                        href="../../../content/policies/starlight_wealth_data_privacy_notice.pdf"
                        className="terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a
                        href="../../../content/policies/starlight_wealth_remuneration_and_fee_schedule.pdf"
                        className="terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Remuneration Policy
                      </a>
                      .
                    </label>
                  </Col>
                </Row>
                <Button
                  disabled={!isCheckboxChecked}
                  className="signin-btn-style"
                  type="submit"
                >
                  Reset
                </Button>
              </ModalFooter>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default memo(ResetPasswordModal);
